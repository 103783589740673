<template>
<!-- セレクトボックス、ラジオボタン、チェックボックスの作成エリア -->
  <b-form>
    <b-row class="mt-2">
      <b-col sm="3" class="inputLabel">
        <label>テンプレート</label>
      </b-col>
      <b-col sm="5">
        <b-form-select
              :value="template"
              @input="updateTemplate"
          :options="fieldTemplate">
          <template v-slot:first>
            <option :value="0">-- 選択してください。 --</option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="mt-2 pb-2">
      <b-col sm="3" class="inputLabel">
        <label>ラベルテキスト</label>
      </b-col>
      <b-col sm="5">
        <b-form-input
          :value="label"
          @input="updateLabel"
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <div
      v-for="(option, index) in options"
      :key="index"
      class="mt-3">
      <b-row>
        <b-col>
          <label class="flex flexCenter">入力項目{{index+1}}</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3" class="inputLabel">
          <label>ラベル</label>
        </b-col>
        <b-col sm="5">
          <b-form-input
            placeholder="入力してください。"
            v-model="option.text"
          ></b-form-input>
        </b-col>
        <b-col class="flex flexLeft flexMiddle closeBtnWrap mx-0 px-0">
          <button type="button"
            class="close"
            aria-label="Close"
            @click="removeOption(index)"
            v-if="index>0"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col sm="3" class="inputLabel">
          <label>接頭辞</label>
        </b-col>
        <b-col sm="3">
          <b-form-input
            placeholder="入力してください。"
            v-model="option.prefix"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col sm="3" class="inputLabel">
          <label>接尾辞</label>
        </b-col>
        <b-col sm="3">
          <b-form-input
            placeholder="入力してください。"
            v-model="option.suffix"
          ></b-form-input>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col sm="3" class="inputLabel">
          <label>入力ルール</label>
        </b-col>
        <b-col sm="3">
        <b-button class="btn btn-lg float-left"
          @click="openModal(index)"
          size="sm"
          >設定する</b-button>
        </b-col>
      </b-row>
    </div>

  </b-form>
</template>

<script>
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'CmsContentsFieldsPartsTexts',
  props: ['openModal'],
  components: {
  },
  data() {
    return {
      dragOption: {
        animation: 200,
      },
    };
  },
  methods: {
    updateLabel(value) {
      this.$store.commit('cmsContentsField/updateLabel', value);
    },
    updateTemplate(value) {
      this.$store.commit('cmsContentsField/updateTemplate', value);
    },
  },
  computed: {
    label() {
      return this.$store.state.cmsContentsField.label;
    },
    template() {
      return this.$store.state.cmsContentsField.view_template;
    },
    options() {
      return this.$store.state.cmsContentsField.options;
    },
    fieldTemplate() {
      const { type } = this.$store.state.cmsContentsField;
      return CONST_OPTIONS.FIELD_TEMPLATES[type];
    },
  },
};
</script>

<style scoped>
  .closeBtnWrap {
    flex-grow: 0.2;
  }
</style>
