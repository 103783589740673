<template>
  <b-modal
    :id="id"
    title="入力ルール - 設定"
    ok-title="設定"
    centered
    size="xl"
    ok-only
    >

    <v-switch-btn :value="viewHelp" @toggleSwitch="switchHelper"/>

    <b-form-group>
      <div class="row"
        v-for="(towRuleList, key) in viewRuleList"
        :key="key">
        <div class="col-sm my-1 pl-5"
        v-for="(ruleId, key) in towRuleList"
        :key="key">
          <span>
            <b-form inline
              v-bind:id="'check'+ruleData[ruleId].id">
              <b-form-checkbox
                @change="toggled($event, ruleId)"
                :checked="selectedRuleIdList"
                v-bind:value="ruleData[ruleId].id">
                {{ruleData[ruleId].name}}
              </b-form-checkbox>
              <div v-if="ruleId in selectedRuleParameters">
                <b-form-input v-for="(parameter, index) in ruleData[ruleId]['min_parameter']"
                  :disabled="!selectedRuleIdList.includes(ruleData[ruleId].id)"
                  @change="updateRuleParameter($event, ruleId, index)"
                  :value="selectedRuleParameters[ruleId][index]"
                  :key="index"
                  :class="['col-sm-4', index === 0 ? 'ml-3' : 'ml-1']"
                  size="sm"/>
              </div>
            </b-form>
          </span>
          <b-tooltip
            v-bind:target="'check'+ruleData[ruleId].id"
            triggers="hover"
            :disabled="!viewHelp">
            {{ruleData[ruleId].description}}
          </b-tooltip>
        </div>
        <!-- 1要素しかない場合、空のhtml要素を置いてレイアウトを整える -->
        <div class="col-sm" v-if="towRuleList.length < 2"></div>
      </div>
    </b-form-group>
  </b-modal>
</template>

<script>
import SwitchBtn from '@/components/parts/SwitchBtn.vue';

export default {
  name: 'CmsContentsFieldsPopupValidation',
  props: ['id'],
  components: {
    'v-switch-btn': SwitchBtn,
  },
  data() {
    return {
    };
  },
  methods: {
    toggled(value, ruleId) {
      const param = this.ruleData[ruleId];
      if (value !== null) {
        // チェック時
        this.$store.dispatch('cmsContentsField/selectRule', param);
      } else {
        // 解除時
        this.$store.dispatch('cmsContentsField/unselectRule', param);
      }
    },
    switchHelper(bool) {
      this.$store.dispatch('cmsUserSetting/setHelp4ValidationRule', bool);
    },
    setRules() {
      this.$store.commit('cmsContentsField/setRules', this.localSelectedRuleIdList);
    },
    updateRuleParameter(value, ruleId, parameterIndex) {
      const param = {
        ruleId,
        value,
        parameterIndex,
      };
      this.$store.commit('cmsContentsField/updateRuleParameter', param);
    },
  },
  computed: {
    type() {
      return this.$store.state.cmsContentsField.type;
    },
    ruleData() {
      return this.$store.state.validation.rules;
    },
    ruleListEachType() {
      return this.$store.state.validation.rulesEachType[this.type];
    },
    viewRuleList() {
      if (!this.ruleListEachType) {
        return [];
      }
      const n = 2;
      const copyArr = this.ruleListEachType.slice();
      const copyArrList = [];
      const index = 0;
      while (index < copyArr.length) {
        copyArrList.push(copyArr.splice(index, index + n));
      }
      return copyArrList;
    },
    viewHelp() {
      return this.$store.state.cmsUserSetting.on_help_validation;
    },
    viewModalIndex() {
      return this.$store.state.cmsContentsField._viewModalIndex;
    },
    selectedRuleIdList() {
      if (this.viewModalIndex === null) {
        return [];
      }
      const { options } = this.$store.state.cmsContentsField;
      return options[this.viewModalIndex].selectedRuleIdList;
    },
    selectedRuleParameters() {
      if (this.viewModalIndex === null) {
        return [];
      }
      const { options } = this.$store.state.cmsContentsField;
      return options[this.viewModalIndex].selectedRuleParameters;
    },
  },
  created() {
  },
};
</script>

<style scoped>

</style>
