<template>
<!-- セレクトボックス、ラジオボタン、チェックボックスの作成エリア -->
  <b-form>
    <b-row class="mt-2">
      <b-col sm="3" class="inputLabel">
        <label>テンプレート</label>
      </b-col>
      <b-col sm="5">
        <b-form-select
              :value="template"
              @input="updateTemplate"
          :options="fieldTemplateOption">
          <template v-slot:first>
            <option :value="0">-- 選択してください。 --</option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="inputLabel">
        <label>ラベルテキスト</label>
      </b-col>
      <b-col sm="5">
        <b-form-input
          :value="label"
          @input="updateLabel"
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="inputLabel">
        <label>回答欄可変</label>
      </b-col>
      <b-col sm="1">
        <b-form-checkbox
          @change="updateIsVariable"
          :checked="isVariable"
          switches
          ></b-form-checkbox>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="isVariable">
      <b-col sm="3" class="inputLabel">
        <label>初期回答欄数</label>
      </b-col>
      <b-col sm="3">
        <b-form-input
          @input="updateInputNumber"
          :value="inputNumber"
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="isVariable">
      <b-col sm="3" class="inputLabel">
        <label>最小回答欄数</label>
      </b-col>
      <b-col sm="3">
        <b-form-input
          @input="updateMinInputNumber"
          :value="minInputNumber"
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="isVariable">
      <b-col sm="3" class="inputLabel">
        <label>最大回答欄数</label>
      </b-col>
      <b-col sm="3">
        <b-form-input
          @input="updateMaxInputNumber"
          :value="maxInputNumber"
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <div v-for="(option, index) in options" :key="index">
      <b-row class="mt-3">
        <b-col sm="3" class="inputLabel">
          <label>列{{index+1}}</label>
        </b-col>
        <b-col sm="5">
          <b-form-input
            placeholder="入力してください。"
            v-model="option.label"
          ></b-form-input>
        </b-col>
        <b-col class="flex flexLeft flexMiddle closeBtnWrap mx-0 px-0">
          <button type="button"
            class="close"
            aria-label="Close"
            @click="removeOption(index)"
            v-if="index>0"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </b-col>
      </b-row>

      <b-row sm="12" class="p-0 mt-1">
        <b-col sm="2"></b-col>
        <b-col sm="2" class="inputLabel">
          <label>テンプレート</label>
        </b-col>
        <b-col sm="4">
          <b-form-select
            v-model="option.view_template"
            :options="optionsOption">
          <template v-slot:first>
            <option :value="null">-- 選択してください。 --</option>
          </template>
          </b-form-select>
        </b-col>
      </b-row>

      <b-row sm="12" class="p-0 mt-1">
        <b-col sm="2"></b-col>
        <b-col sm="2" class="inputLabel">
          <label>入力方式</label>
        </b-col>
        <b-col sm="4">
          <b-form-select
            v-model="option.input_type"
            :options="optionTableInputTypes">
          <template v-slot:first>
            <option :value="null">-- 選択してください。 --</option>
          </template>
          </b-form-select>
        </b-col>
      </b-row>

      <!-- テキスト入力の時は入力ルールを設定 -->
      <b-row sm="12"
        v-if="option.input_type===tableInputTypes.TEXT"
        class="p-0 mt-1">
        <b-col sm="2"></b-col>
        <b-col sm="2" class="inputLabel">
          <label>入力ルール</label>
        </b-col>
        <b-col sm="4">
        <b-button class="btn btn-lg float-left"
          @click="openModal(index)"
          size="sm"
          >設定する</b-button>
        </b-col>
      </b-row>

      <!-- 選択入力の時は、選択肢を作成 -->
      <b-row sm="12"
        v-if="option.input_type===tableInputTypes.SELECT"
        class="p-0 mt-1">
        <b-col sm="2"></b-col>
        <b-col sm="2" class="inputLabel">
          <label>選択肢(|区切りで入力)</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            :value="option.choice_texts.join('|')"
            @input="updateChoiceTexts($event, index)"/>
        </b-col>
      </b-row>

      <!-- 固定テキストの時は、固定テキストを作成 -->
      <b-row sm="12"
        v-if="option.input_type===tableInputTypes.FIXED"
        class="p-0 mt-1">
        <b-col sm="2"></b-col>
        <b-col sm="2" class="inputLabel">
          <label>表示テキスト(|区切りで入力)</label>
        </b-col>
        <b-col sm="7">
          <b-form-input
            :value="option.choice_texts.join('|')"
            @input="updateChoiceTexts($event, index)"/>
        </b-col>
      </b-row>

    </div>

    <b-row class="mt-2 ml-5">
      <b-col sm="3" class="inputLabel">
        <unicon name="plus-circle"
          fill="#495057"
          @click="addOption"
          class="unicon"></unicon>
      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'CmsContentsFieldsPartsTables',
  props: ['openModal'],
  components: {
  },
  data() {
    return {
      dragOption: {
        animation: 200,
      },
    };
  },
  methods: {
    updateLabel(value) {
      this.$store.commit('cmsContentsField/updateLabel', value);
    },
    updateTemplate(value) {
      this.$store.commit('cmsContentsField/updateTemplate', value);
    },
    updateIsVariable() {
      const newIsVariable = !this.isVariable;
      this.$store.commit('cmsContentsField/updateIsVariable', newIsVariable);
    },
    updateInputNumber(value) {
      this.$store.commit('cmsContentsField/updateInputNumber', value);
    },
    updateMinInputNumber(value) {
      this.$store.commit('cmsContentsField/updateMinInputNumber', value);
    },
    updateMaxInputNumber(value) {
      this.$store.commit('cmsContentsField/updateMaxInputNumber', value);
    },
    addOption() {
      this.$store.commit('cmsContentsField/addOption');
    },
    removeOption(index) {
      this.$store.commit('cmsContentsField/removeOption', index);
    },
    updateChoiceTexts(value, index) {
      const textArray = value.split('|');
      const param = {
        value: textArray,
        index,
      };
      this.$store.commit('cmsContentsField/updateChoiceTexts', param);
    },
  },
  computed: {
    label() {
      return this.$store.state.cmsContentsField.label;
    },
    template() {
      return this.$store.state.cmsContentsField.view_template;
    },
    fieldTemplateOption() {
      const { type } = this.$store.state.cmsContentsField;
      return CONST_OPTIONS.FIELD_TEMPLATES[type];
    },
    optionsOption() {
      return CONST_OPTIONS.TABLE_COLUMN_TEMPLATES;
    },
    tableInputTypes() {
      return CONST_OPTIONS.TABLE_INPUT_TYEPS;
    },
    optionTableInputTypes() {
      return CONST_OPTIONS.TABLE_INPUT_TYEPS_OPTION;
    },
    options() {
      return this.$store.state.cmsContentsField.options;
    },
    // ユーザー側解答欄数関連
    isVariable() {
      return this.$store.state.cmsContentsField.is_variable;
    },
    inputNumber() {
      return this.$store.state.cmsContentsField.input_number;
    },
    minInputNumber() {
      return this.$store.state.cmsContentsField.min_input_number;
    },
    maxInputNumber() {
      return this.$store.state.cmsContentsField.max_input_number;
    },
  },
};
</script>

<style scoped>
  .closeBtnWrap {
    flex-grow: 0.2;
  }
</style>
