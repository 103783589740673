<template>
<!-- セレクトボックス、ラジオボタン、チェックボックスの作成エリア -->
  <b-form>
    <b-row class="mt-2">
      <b-col sm="3" class="inputLabel">
        <label>テンプレート</label>
      </b-col>
      <b-col sm="5">
        <b-form-select
              :value="template"
              @input="updateTemplate"
          :options="fieldTemplate">
          <template v-slot:first>
            <option :value="0">-- 選択してください。 --</option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col sm="3" class="inputLabel">
        <label>ラベルテキスト</label>
      </b-col>
      <b-col sm="5">
        <b-form-input
          :value="label"
          @input="updateLabel"
          placeholder="入力してください。"></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col sm="3" class="inputLabel">
        <label>入力ルール</label>
      </b-col>
      <b-col sm="3">
      <b-button class="btn btn-lg float-left"
        @click="openModal(0)"
        size="sm"
        >設定する</b-button>
      </b-col>
    </b-row>

  </b-form>
</template>

<script>
import CONST_OPTIONS from '@/constants/options';

export default {
  name: 'CmsContentsFieldsPartsFiles',
  props: ['openModal'],
  components: {
  },
  data() {
    return {
      dragOption: {
        animation: 200,
      },
    };
  },
  methods: {
    updateLabel(value) {
      this.$store.commit('cmsContentsField/updateLabel', value);
    },
    updateTemplate(value) {
      this.$store.commit('cmsContentsField/updateTemplate', value);
    },
  },
  computed: {
    label() {
      return this.$store.state.cmsContentsField.label;
    },
    template() {
      return this.$store.state.cmsContentsField.view_template;
    },
    fieldTemplate() {
      const { type } = this.$store.state.cmsContentsField;
      return CONST_OPTIONS.FIELD_TEMPLATES[type];
    },
  },
};
</script>

<style scoped>
  .closeBtnWrap {
    flex-grow: 0.2;
  }
</style>
