<template>
  <div class="clearfix">
    <div class="float-right">
      <span class="mr-2">ヘルプ表示</span>
      <div v-bind:class="['switchArea', sizeClass]">
        <input type="checkbox" id="switch"
          v-bind:value="dataOn"
          v-bind:checked="dataOn"
          @change="switchHelper">
        <label for="switch"><span></span></label>
        <div id="swImg"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartsSwitchBtn',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    // 'small' or 'large'
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      dataOn: this.value,
    };
  },
  computed: {
    sizeClass() {
      let text = 'small';
      if (this.size === 'large') {
        text = 'large';
      }
      return text;
    },
  },
  methods: {
    switchHelper() {
      this.dataOn = !this.dataOn;
      this.$emit('toggleSwitch', this.dataOn);
    },
  },
};
</script>

<style>
 /* === ボタンを表示するエリア ============================== */
 .switchArea {
  display: inline-block;
  letter-spacing : 0;
  text-align     : center;
  position       : relative;
  margin         : auto;
  background     : #fff;
}
 .switchArea.large {
  line-height    : 42px;
  width          : 106px;
  font-size      : 18px;
}
 .switchArea.small {
  line-height    : 22px;
  width          : 76px;
  font-size      : 16px;
}

 /* === チェックボックス ==================================== */
.switchArea input[type="checkbox"] {
  display        : none;
}

.switchArea label {
  display        : block;
  box-sizing     : border-box;
  border-radius  : 21px;
}
.switchArea.large label {
  border         : 2px solid #a6a6a6;
  height         : 42px;
}
.switchArea.small label {
  border         : 1px solid #a6a6a6;
  height         : 22px;
}

 /* === チェックボックスのラベル（ONのとき） ================ */
.switchArea input[type="checkbox"]:checked +label {
  border-color   : #17a2b8;
}

 /* === 表示する文字（標準） ================================ */
.switchArea label span:after{
  content        : "OFF";
  padding        : 0 0 0 25px;
  color          : #a6a6a6;
}

 /* === 表示する文字（ONのとき） ============================ */
.switchArea  input[type="checkbox"]:checked + label span:after{
  content        : "ON";
  padding        : 0 25px 0 0;
  color          : #17a2b8;
}

 /* === 丸部分のSTYLE（標準） =============================== */
.switchArea #swImg {
  position       : absolute;
  background     : #a6a6a6;
  border-radius  : 17px;
  transition     : .2s;
}
.switchArea.large #swImg {
  width          : 34px;
  height         : 34px;
  top            : 4px;
  left           : 4px;
}
.switchArea.small #swImg {
  width          : 16px;
  height         : 16px;
  top            : 3px;
  left           : 3px;
}

 /* === 丸部分のSTYLE（ONのとき） =========================== */
.switchArea input[type="checkbox"]:checked ~ #swImg {
  background     : #17a2b8;
}
.switchArea.large input[type="checkbox"]:checked ~ #swImg {
  transform      : translateX(64px);
}
.switchArea.small input[type="checkbox"]:checked ~ #swImg {
  transform      : translateX(53px);
}
</style>
