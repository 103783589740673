<template>
  <div id="mainWrap" class="py-2">
    <v-choices v-if="viewTypeChoises" :openModal="openModal"/>
    <v-texts v-if="viewTypeTexts" :openModal="openModal"/>
    <v-files v-if="viewTypeFiles" :openModal="openModal"/>
    <v-tables v-if="viewTypeTables" :openModal="openModal"/>
  </div>
</template>

<script>
import CONST_OPTIONS from '@/constants/options';
import Choices from '@/components/cms/contents/fields/parts/Choices.vue';
import Texts from '@/components/cms/contents/fields/parts/Texts.vue';
import Files from '@/components/cms/contents/fields/parts/Files.vue';
import Tables from '@/components/cms/contents/fields/parts/Tables.vue';

export default {
  name: 'CmsContentsFieldsBase',
  components: {
    'v-choices': Choices,
    'v-texts': Texts,
    'v-files': Files,
    'v-tables': Tables,
  },
  data() {
    return {
    };
  },
  methods: {
    openModal(index) {
      this.$store.commit('cmsContentsField/updateViewModalIndex', index);
      this.$bvModal.show('validationPopup');
    },
  },
  computed: {
    type() {
      return this.$store.state.cmsContentsField.type;
    },
    label() {
      return this.$store.state.cmsContentsField.label;
    },
    viewTypeChoises() {
      const isCheck = this.type === CONST_OPTIONS.FIELD_TYPE.CHECKBOX.value;
      const isRadio = this.type === CONST_OPTIONS.FIELD_TYPE.RADIO.value;
      const isSelect = this.type === CONST_OPTIONS.FIELD_TYPE.SELECT.value;
      return isCheck || isRadio || isSelect;
    },
    viewTypeTexts() {
      const isText = this.type === CONST_OPTIONS.FIELD_TYPE.TEXT.value;
      const isTextArea = this.type === CONST_OPTIONS.FIELD_TYPE.TEXTAREA.value;
      return isText || isTextArea;
    },
    viewTypeFiles() {
      return this.type === CONST_OPTIONS.FIELD_TYPE.FILE.value;
    },
    viewTypeTables() {
      return this.type === CONST_OPTIONS.FIELD_TYPE.TABLE.value;
    },
  },
};
</script>

<style scoped>
  #mainWrap {
    border: solid 1px;
    /* height: 400px;
    overflow-y: scroll; */
    overflow-x: hidden;
  }
</style>
